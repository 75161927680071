<template>
  <octo-table
    show-filters
    enable-export
    :action="endpoints.DATATABLES.lessons"
    :fields="fields"
    :filters="filters"
    :name="name"
    :order-by-items="['date']"
    @onResetFilters="filters = {}"
  >
    <template v-slot:date="data">
      <label-theme-component class="text-uppercase">{{ data.row.date | day('short') }}</label-theme-component>
      {{ data.row | optional('date') | date }}
    </template>

    <template v-slot:time="data">
      <small>{{ data.row | optional('time_start') | time }} - {{ data.row | optional('time_end') | time }}</small>
    </template>

    <template v-slot:status="data">
      <badge :type="data.row.status | lessonStatusColor" class="text-uppercase">
        {{ $t('didactics.lesson_status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:type="data">
      <div class="text-uppercase">{{ $t('didactics.lesson_type.' + data.row.type) }}</div>
    </template>

    <template v-slot:location="data">
      <div class="text-uppercase">{{ getLocationName(data.row.location_id) }}</div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button class="mx-1" link size="sm" icon @click="redirectToClassroom(data.row.classroom)">
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-6">
        <base-input :label="$t('fields.tutors')" v-if="isTeachingManager">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.tutorIds"
          >
            <el-option
              class="select-default text-uppercase"
              v-for="tutor in tutors"
              :value="tutor.id"
              :label="`${$options.filters.optional(tutor, 'firstname')}
                       ${$options.filters.optional(tutor, 'lastname')}`"
              :key="`tutor-${tutor.id}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-6">
        <base-input :label="$t('fields.lesson_tutor_id')" v-if="isTeachingManager">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.lessonTutorIds"
          >
            <el-option
              class="select-default text-uppercase"
              v-for="tutor in tutors"
              :value="tutor.id"
              :label="`${$options.filters.optional(tutor, 'firstname')}
                       ${$options.filters.optional(tutor, 'lastname')}`"
              :key="`tutor-lesson-${tutor.id}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.status')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.statuses"
          >
            <el-option
              v-for="status in statuses"
              class="select-default text-uppercase"
              :value="status"
              :label="$t('didactics.lesson_status.' + status)"
              :key="status"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('fields.date')">
          <el-date-picker
            v-model="filters.dateRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import {Select, Option, DatePicker} from "element-ui";
import {mapGetters} from "vuex";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";
import LessonStatuses from "@/pages/Didactics/resources/lessonStatuses";
import {permissionService} from "@/util/permission-service";

export default {
  name: "LessonDatatable",
  components: {
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      name: 'LessonDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'code', label: 'code', width: 300, fixed: true},
        {prop: 'date', label: 'date', width: 150, slot: true, align: 'center'},
        {prop: 'time', label: 'time', width: 150, slot: true, align: 'center'},
        {prop: 'status', label: 'status', minWidth: 150, slot: true, align: 'center'},
        {prop: 'type', label: 'type', width: 200, slot: true, align: 'center'},
        {prop: 'location', label: 'location', width: 200, slot: true},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'},
      ],
      statuses: [
        LessonStatuses.scheduled.value,
        LessonStatuses.completed.value,
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      locations: 'common/locations',
      tutors: 'common/tutors'
    }),

    pickerOptions() {
      return {firstDayOfWeek: 1};
    },

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },
  },
  methods: {
    redirectToClassroom(classroom) {
      if (classroom.type === ClassroomTypes.certificate.value) {
        this.$router.push({name: 'didactics.classrooms.archive.certificate.show', params: {id: classroom.id}});
      } else {
        this.$router.push({name: 'didactics.classrooms.on-going.show', params: {id: classroom.id}});
      }
    },

    getLocationName(locationId) {
      if (!locationId) {
        return this.$t('common.virtual');
      }

      return this.$_.find(this.locations, location => location.id === locationId)?.alias;
    }
  }
}
</script>

<style scoped>

</style>
