<template>
  <div class="row">
    <div class="col-12">
      <lesson-datatable/>
    </div>
  </div>
</template>

<script>
import LessonDatatable from "@/pages/Didactics/datatables/LessonDatatable";

export default {
  name: "IndexLessonPage",
  components: {LessonDatatable},
}
</script>

<style scoped>

</style>
